import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "category_carousal space" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_carousel = _resolveComponent("category-carousel")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "cmn_heading_area container" }, [
      _createElementVNode("h2", { class: "heading sairaR container-fluid" }, [
        _createTextVNode(" Top "),
        _createElementVNode("span", { class: "cm-color" }, "Categories")
      ])
    ], -1)),
    _createVNode(_component_category_carousel, {
      categories: _ctx.categories,
      onOnSelect: _ctx.navigateCategories
    }, null, 8, ["categories", "onOnSelect"])
  ]))
}