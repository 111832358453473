import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/email-icon.svg'
import _imports_1 from '@/assets/images/google-logo.png'


const _hoisted_1 = {
  class: "modal-dialog model-sm modal-dialog-centered",
  role: "document"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-body text-center" }
const _hoisted_4 = { class: "sairaR mb-5" }
const _hoisted_5 = { class: "login_btns" }
const _hoisted_6 = { class: "form-text text-muted dont_have_account" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["modal fade bd-answer-modal-sm authenticate_model", { show: _ctx.show }]),
        id: "answerModalCenter",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "answerModalCenterTitle",
        "aria-hidden": "true"
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "model-overlay" }, null, -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                type: "button",
                class: "close",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClose()))
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("span", { "aria-hidden": "true" }, "×", -1)
              ])),
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
              _createElementVNode("ul", _hoisted_5, [
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, {
                    to: { name: 'SignUp' },
                    class: "cm-btn gmail_btn",
                    onClick: _ctx.onClose
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Google Logo image"
                      }, null, -1),
                      _createTextVNode("Sign Up With Email")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_a_button, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onGoogleSignIn())),
                    class: "cm-btn google_btn"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("img", {
                        src: _imports_1,
                        alt: "Google Logo image"
                      }, null, -1),
                      _createTextVNode(" Sign In With Google ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("small", _hoisted_6, [
                _cache[6] || (_cache[6] = _createTextVNode("Already have an account? ")),
                _createVNode(_component_router_link, {
                  to: { name: 'Login' },
                  class: "cm-color",
                  onClick: _ctx.onClose
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Log in")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}