import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home-category_img.png'


const _hoisted_1 = { class: "category_blocks_items mb-3" }
const _hoisted_2 = { class: "category_blocks_cont" }
const _hoisted_3 = { class: "text-white text-capitalize" }
const _hoisted_4 = { class: "badge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "category_blocks_img" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "img-fluid"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, _toDisplayString($props.category.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString($props.category._count.topics_categories) + " Topics", 1)
    ])
  ]))
}