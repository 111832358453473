import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "search_box" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "fa" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "search_results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_bar_card = _resolveComponent("search-bar-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search_bar", _ctx.searchBarOpened])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          type: "text",
          value: _ctx.searchText,
          onInput: _cache[0] || (_cache[0] = (evt) => { if (evt.target) _ctx.searchText = (evt.target as HTMLInputElement).value; }),
          class: "form-control",
          autocomplete: "off",
          placeholder: "Type a topic..."
        }, null, 40, _hoisted_3),
        _createElementVNode("i", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.searchIcon,
            alt: ""
          }, null, 8, _hoisted_5)
        ])
      ])
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (res) => {
            return (_openBlock(), _createBlock(_component_search_bar_card, {
              key: res.id,
              image: res.image,
              title: res.title,
              onClick: ($event: any) => (_ctx.onClickTopic(res.slug))
            }, null, 8, ["image", "title", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}