
import { defineComponent } from "@vue/runtime-core";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import "./styles.css";
export default defineComponent({
  emits: ["onClose"],
  props: {
    show: Boolean,
    title: String,
  },

  methods: {
    onClose() {
      this.$emit("onClose");
    },

    async onGoogleSignIn() {
      this.$emit("onClose");
      try {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        await signInWithPopup(auth, provider);
        if (this.$route.name === "Login" || this.$route.name === "SignUp") {
          setTimeout(() => {
            this.$this.$router.push({
              name: "Home",
            });
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
