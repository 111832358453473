
import { defineComponent } from "vue";
import SearchSection from "@/views/home/SearchSection.vue";
import FeaturedCategoriesSection from "./FeaturedCategoriesSection.vue";
import AboutSection from "./AboutSection.vue";
import PhilosophySection from "./PhilosophySection.vue";
import LatestTopicsSection from "./LatestTopicsSection.vue";
import LearnNewThings from "./LearnNewThingsSection.vue";
import WorldSection from "./WorldSection.vue";
import LatestBlogsSection from "./LatestBlogsSection.vue";
import Loader from "@/components/ui/Loader.vue";
import { getTopics } from "@toppick/common/build/api/topic";

import {
  CategoryFeatured,
  TopicFeatured,
} from "@toppick/common/build/interfaces";
import EmailNotConfirmedModal from "@/components/auth/EmailNotConfirmedModal.vue";
import "./styles.css";
import { getCategories } from "@toppick/common/build/api";
import PickerActionsButton from "@/components/ui/PickerActionsButton.vue";
const LOADED_LATEST_TOPICS = 3;
const LOADED_FEATURED_CATEGORIES = 20;

export default defineComponent({
  name: "Home",
  components: {
    SearchSection,
    AboutSection,
    PhilosophySection,
    LatestTopicsSection,
    WorldSection,
    FeaturedCategoriesSection,
    LearnNewThings,
    LatestBlogsSection,
    Loader,
    EmailNotConfirmedModal,
    PickerActionsButton,
  },
  data() {
    return {
      isLoading: true,
      latestTopics: [] as TopicFeatured[],
      featuredCategories: [] as CategoryFeatured[],
    };
  },

  async mounted() {
    try {
      this.latestTopics = await getTopics({
        limit: LOADED_LATEST_TOPICS,
        skip: 0,
        include_categories: true,
        sort_by_timestamp: true,
      });
      this.featuredCategories = await getCategories({
        limit: LOADED_FEATURED_CATEGORIES,
        count_topics: true,
      });
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  },
});
