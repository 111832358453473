import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "latest_blog_box mb-4" }
const _hoisted_2 = { class: "latest_blog_img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "latest_blog_box_cont" }
const _hoisted_5 = { class: "badge" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "blog_time montRI" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "blog_text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.blog.featuredimage.url,
        alt: "",
        class: "img-fluid"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.blog.tags.title), 1),
      _createElementVNode("h3", _hoisted_6, [
        _createElementVNode("a", null, _toDisplayString(_ctx.blog.title), 1)
      ]),
      _createElementVNode("span", _hoisted_7, [
        _createElementVNode("img", {
          src: _ctx.accessTime,
          alt: "",
          class: "img-fluid"
        }, null, 8, _hoisted_8),
        _createTextVNode(" " + _toDisplayString(_ctx.displayedTime), 1)
      ]),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.blog.excerpt), 1),
      _cache[0] || (_cache[0] = _createElementVNode("a", {
        href: "#",
        class: "latest_blog_btn sairaSB"
      }, "Read now", -1))
    ])
  ]))
}