
export default {
  data() {
    return {
      arrowIcon: require("@/assets/images/right-arrow.svg"),
    };
  },

  methods: {
    onClick() {
      this.$router.push({
        name: "Blog",
        params: { slug: "what-is-toppick" },
      });
    },
  },
};
