import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home-about_img.png'


const _hoisted_1 = { class: "about_outer space" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "about_content" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-md-6" }, [
          _createElementVNode("div", { class: "about_img" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "tittle_cm" }, [
              _createTextVNode(" What is "),
              _createElementVNode("span", { class: "text-white" }, "TopPick")
            ], -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mt-3 mb-4" }, " TopPick is a platform that can improve many aspects of your personal and professional life. Discover its uses and features to get the best possible experience. ", -1)),
            _createVNode(_component_router_link, {
              class: "cm-btn",
              to: {
                name: 'BlogDetail',
                params: { slug: 'what-is-toppick' },
              }
            }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createTextVNode("Learn more ")),
                _createElementVNode("img", {
                  src: $data.arrowIcon,
                  alt: "",
                  class: "ml-1"
                }, null, 8, _hoisted_6)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}