import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/home-blog_Ads1.png'
import _imports_1 from '@/assets/images/home-blog_Ads2.png'
import _imports_2 from '@/assets/images/home-blog_Ads3.png'


const _hoisted_1 = { class: "latest_blog space" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "latest_blog_wrap" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_blog_card_latest = _resolveComponent("blog-card-latest")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "cmn_heading_area" }, [
        _createElementVNode("h2", { class: "heading" }, [
          _createElementVNode("span", { class: "cm-color" }, "Latest Blog Posts")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogs, (blog) => {
              return (_openBlock(), _createBlock(_component_blog_card_latest, {
                key: blog.id,
                blog: blog
              }, null, 8, ["blog"]))
            }), 128))
          ]),
          _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"col-lg-4\"><div class=\"latest_blog_ads\"><div class=\"row\"><div class=\"col-lg-12 col-md-4 col-sm-6 ads_img\"><img src=\"" + _imports_0 + "\" alt=\"\" class=\"img-fluid pb-4\"></div><div class=\"col-lg-12 col-md-4 col-sm-6 ads_img\"><img src=\"" + _imports_1 + "\" alt=\"\" class=\"img-fluid pb-4\"></div><div class=\"col-lg-12 col-md-4 col-sm-6 ads_img\"><img src=\"" + _imports_2 + "\" alt=\"\" class=\"img-fluid pb-4\"></div></div></div></div>", 1))
        ])
      ])
    ])
  ]))
}