import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/home-blog_Ads1.png'
import _imports_1 from '@/assets/images/home-blog_Ads2.png'


const _hoisted_1 = { class: "latest_topics" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "latest_topics_wrap" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "col-lg-8"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-12" }
const _hoisted_8 = { class: "col-lg-4" }
const _hoisted_9 = { class: "category_blocks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_latest_topic_card = _resolveComponent("latest-topic-card")!
  const _component_category_card = _resolveComponent("category-card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "cmn_heading_area" }, [
        _createElementVNode("h2", { class: "heading" }, [
          _createElementVNode("span", { class: "cm-color" }, "Latest Topics")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.topics.length > 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topics.slice(0, 2), (topic) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-sm-6",
                      key: topic.id
                    }, [
                      _createVNode(_component_latest_topic_card, {
                        topic: topic,
                        onClickTopic: ($event: any) => (_ctx.onClickTopic(topic.slug))
                      }, null, 8, ["topic", "onClickTopic"])
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_latest_topic_card, {
                      topic: _ctx.topics[2],
                      onClickTopic: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickTopic(_ctx.topics[2].slug)))
                    }, null, 8, ["topic"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"latest_blog_ads\"><div class=\"row\"><div class=\"col-lg-12 col-sm-6 ads_img\"><img src=\"" + _imports_0 + "\" alt=\"\" class=\"img-fluid pb-4\"></div><div class=\"col-lg-12 col-sm-6 ads_img\"><img src=\"" + _imports_1 + "\" alt=\"\" class=\"img-fluid pb-4\"></div></div></div>", 1)),
            _createElementVNode("div", _hoisted_9, [
              _cache[1] || (_cache[1] = _createElementVNode("h4", null, "Categories", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories.slice(0, 4), (category) => {
                return (_openBlock(), _createBlock(_component_category_card, {
                  key: category.id,
                  category: category,
                  onClick: ($event: any) => (_ctx.onClickCategory(category.slug))
                }, null, 8, ["category", "onClick"]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}