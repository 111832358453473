
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { UserFeatured } from "@toppick/common/build/interfaces";
import { getInitials } from "@/utils/ui";
import "./styles.css";
import { hasPriviledgedRole } from "@/utils/auth";
export default defineComponent({
  props: {
    user: {
      required: true,
      type: Object as PropType<UserFeatured>,
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDefaultImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClickAvatar(e) {
      e.stopPropagation();
    },
  },
  computed: {
    initials(): string {
      return getInitials(this.user.username);
    },
    hasPriviledgedRole(): boolean {
      return hasPriviledgedRole(this.$store.getters.getUserRole)
    },
  },
});
