import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "topbar cm_bg" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "ticker" }
const _hoisted_6 = { class: "news" }
const _hoisted_7 = { class: "news-content" }
const _hoisted_8 = { class: "navbar navbar-expand-lg navbar-light py-3" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = {
  key: 0,
  class: "nav-item user"
}
const _hoisted_11 = { class: "navbar-nav ml-auto" }
const _hoisted_12 = { class: "menu_logo" }
const _hoisted_13 = {
  key: 0,
  class: "login_btn"
}
const _hoisted_14 = {
  key: 1,
  class: "nav-item user"
}
const _hoisted_15 = { class: "user_dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _component_auth_modal = _resolveComponent("auth-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "title" }, [
                _createElementVNode("h5", { style: {"color":"white !important"} }, "Latest news")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("nav", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, {
              class: "navbar-brand cm-color",
              to: { name: 'Home' },
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeMenu()))
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("TopPick")
              ])),
              _: 1
            }),
            (_ctx.isAuthenticated)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_user_avatar, {
                    user: _ctx.currentUser,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleAvtar()))
                  }, null, 8, ["user"]),
                  _createElementVNode("ul", {
                    class: _normalizeClass(["user_dropdown", { show: _ctx.isAvtarShow }])
                  }, [
                    _createElementVNode("li", {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeMenu()))
                    }, [
                      _createElementVNode("button", {
                        class: "usermenu_item",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goProfile && _ctx.goProfile(...args)))
                      }, _cache[18] || (_cache[18] = [
                        _createElementVNode("i", { class: "fa fa-user-o" }, null, -1),
                        _createTextVNode(" Profile ")
                      ]))
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("button", {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onLogout())),
                        class: "usermenu_item"
                      }, _cache[19] || (_cache[19] = [
                        _createElementVNode("i", { class: "fa fa-sign-out" }, null, -1),
                        _createTextVNode(" Logout ")
                      ]))
                    ])
                  ], 2)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: _normalizeClass(["navbar-toggler", { collapsed: !_ctx.isOpen }]),
              type: "button",
              "aria-controls": "navbarSupportedContent",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleCard()))
            }, _cache[20] || (_cache[20] = [
              _createElementVNode("span", { class: "fa fa-reorder" }, null, -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["navbar-collapse", { show: _ctx.isOpen, collapse: !_ctx.isOpen }]),
              id: "navbarSupportedContent"
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(["navbar-toggler", { collapsed: !_ctx.isOpen }]),
                type: "button",
                "aria-controls": "navbarSupportedContent",
                "aria-expanded": "false",
                "aria-label": "Toggle navigation",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleCard()))
              }, _cache[21] || (_cache[21] = [
                _createElementVNode("span", { class: "fa fa-remove" }, null, -1)
              ]), 2),
              _cache[31] || (_cache[31] = _createElementVNode("div", { class: "header_overlay" }, null, -1)),
              _createElementVNode("ul", _hoisted_11, [
                _createElementVNode("li", _hoisted_12, [
                  _createVNode(_component_router_link, {
                    class: "text-white",
                    to: { name: 'Home' },
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closeMenu()))
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode("TopPick")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", { active: _ctx.currentRouteName === 'Home' }])
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: { name: 'Home' },
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.closeMenu()))
                  }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode("Home")
                    ])),
                    _: 1
                  })
                ], 2),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", { active: _ctx.currentRouteName === 'Category' }])
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: {
                    name: 'Category',
                    params: {
                      slug: 'all',
                    },
                  },
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closeMenu()))
                  }, {
                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createTextVNode("Library")
                    ])),
                    _: 1
                  })
                ], 2),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", {
                  active: _ctx.currentRouteName === 'MyTopics',
                }])
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: 
                    _ctx.isAuthenticated
                      ? { name: 'MyTopics', params: { tab: 'me' } }
                      : ''
                  ,
                    onClick: _cache[10] || (_cache[10] = ($event: any) => {
                    _ctx.closeMenu();
                    if (!_ctx.isAuthenticated) _ctx.toggleAuthModal();
                  })
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Topics")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ], 2),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", { active: _ctx.currentRouteName === 'Blog' }])
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: { name: 'Blog' },
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.closeMenu()))
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode("Blog")
                    ])),
                    _: 1
                  })
                ], 2),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", { active: _ctx.currentRouteName === 'About' }])
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: { name: 'About' },
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.closeMenu()))
                  }, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode("About")
                    ])),
                    _: 1
                  })
                ], 2),
                (!_ctx.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("button", {
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.toggleAuthModal()))
                      }, _cache[28] || (_cache[28] = [
                        _createElementVNode("i", { class: "fa fa-key" }, null, -1),
                        _createTextVNode(" Login ")
                      ]))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                      _createVNode(_component_user_avatar, { user: _ctx.currentUser }, null, 8, ["user"]),
                      _createElementVNode("ul", _hoisted_15, [
                        _createElementVNode("li", null, [
                          _createElementVNode("button", {
                            class: "usermenu_item",
                            onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.goProfile && _ctx.goProfile(...args)))
                          }, _cache[29] || (_cache[29] = [
                            _createElementVNode("i", { class: "fa fa-user-o" }, null, -1),
                            _createTextVNode(" Profile ")
                          ]))
                        ]),
                        _createElementVNode("li", null, [
                          _createElementVNode("button", {
                            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onLogout())),
                            class: "usermenu_item"
                          }, _cache[30] || (_cache[30] = [
                            _createElementVNode("i", { class: "fa fa-sign-out" }, null, -1),
                            _createTextVNode(" Logout ")
                          ]))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ])
        ])
      ])
    ]),
    _createVNode(_component_auth_modal, {
      title: "Login or Create a New Account",
      show: _ctx.isShowAuthModal,
      onOnClose: _ctx.toggleAuthModal
    }, null, 8, ["show", "onOnClose"])
  ], 64))
}