import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.display)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["loader", {
      fadeout: _ctx.fadeout,
    }])
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "loader-inner" }, [
          _createElementVNode("div", { class: "circle" })
        ], -1)
      ]), 2))
    : _createCommentVNode("", true)
}