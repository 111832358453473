import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/revisit.svg'


const _hoisted_1 = {
  key: 0,
  class: "cookies_foot"
}
const _hoisted_2 = { class: "cookie_foot_content" }
const _hoisted_3 = { class: "cookies_btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cookie_modal = _resolveComponent("cookie-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("h5", null, "We Value Your Privacy", -1)),
          _createElementVNode("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("p", null, " We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking \"Accept All\", you consent to our use of cookies. ", -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showCookieModal && _ctx.showCookieModal(...args)))
              }, "Customize"),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onRejectAll && _ctx.onRejectAll(...args)))
              }, "Reject All"),
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onAcceptAll && _ctx.onAcceptAll(...args))),
                class: "active"
              }, "Accept All")
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "floating-cookie-btn",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showCookieModal && _ctx.showCookieModal(...args)))
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1),
          _createElementVNode("span", null, "Cookie Settings", -1)
        ]))),
    _createVNode(_component_cookie_modal, {
      show: _ctx.isShowCookieModal,
      onOnClose: _ctx.onClose
    }, null, 8, ["show", "onOnClose"])
  ], 64))
}