import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/emailVerify.svg'
import _imports_1 from '@/assets/images/email-sent.svg'


const _hoisted_1 = {
  class: "modal-dialog model-sm modal-dialog-centered",
  role: "document"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { style: {"text-transform":"lowercase"} }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 3,
  src: _imports_1,
  class: "mr-3 image-sent",
  alt: "success message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["modal fade bd-answer-modal-sm authenticate_model verifyMail", { show: _ctx.show }]),
        id: "answerModalCenter",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "answerModalCenterTitle",
        "aria-hidden": "true"
      }, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "model-overlay" }, null, -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[5] || (_cache[5] = _createElementVNode("img", {
                src: _imports_0,
                alt: "Verify eamil icon"
              }, null, -1)),
              (!_ctx.isEmailSent)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_4, [
                    _cache[2] || (_cache[2] = _createTextVNode(" Please verify your Email at ")),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.email), 1)
                  ]))
                : (_openBlock(), _createElementBlock("h5", _hoisted_6, "Check your inbox for the confirmation email")),
              (!_ctx.isEmailSent)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 2,
                    loading: _ctx.isLoading,
                    type: "primary",
                    class: "verifyEmail cm_bg cm-btn text-white border-0 px-5",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendEmailVerification()))
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Resend Email ")
                    ])),
                    _: 1
                  }, 8, ["loading"]))
                : (_openBlock(), _createElementBlock("img", _hoisted_7)),
              _createVNode(_component_a_button, {
                type: "primary",
                class: "verifyEmail cm_bg cm-btn text-white border-0 px-5",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onLogout()))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Logout ")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}