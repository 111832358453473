import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/plus.svg'
import _imports_1 from '@/assets/images/picker_icon.svg'


const _hoisted_1 = { class: "floatings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("a", {
      href: "#",
      class: "float"
    }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Create Icon"
      })
    ], -1)),
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: { name: 'QuestionPickerStart' } }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("span", null, " Question picker", -1),
            _createElementVNode("div", { class: "picker_icon" }, [
              _createElementVNode("img", {
                src: _imports_1,
                alt: ""
              })
            ], -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: { name: 'TopicPickerStart' } }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", null, " Topic Picker", -1),
            _createElementVNode("div", { class: "picker_icon" }, [
              _createElementVNode("img", {
                src: _imports_1,
                alt: ""
              })
            ], -1)
          ])),
          _: 1
        })
      ])
    ])
  ], 64))
}