import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "modal-dialog model-lg modal-dialog-centered",
  role: "document"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "cookie_header" }
const _hoisted_4 = { class: "cookies_body" }
const _hoisted_5 = { class: "cookies_text border-bottom pb-3" }
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_8 = { class: "cookies_text border-bottom pb-3 pt-3" }
const _hoisted_9 = { class: "text_header mb-2" }
const _hoisted_10 = { class: "switch" }
const _hoisted_11 = { class: "cookies_text border-bottom pb-3 pt-3" }
const _hoisted_12 = { class: "text_header mb-2" }
const _hoisted_13 = { class: "switch" }
const _hoisted_14 = { class: "cookies_text pb-3 pt-3" }
const _hoisted_15 = { class: "text_header mb-2" }
const _hoisted_16 = { class: "switch" }
const _hoisted_17 = { class: "cookies_button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["modal fade bd-answer-modal-sm cookies_model", { show: _ctx.show }]),
        id: "answerModalCenter",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "answerModalCenterTitle",
        "aria-hidden": "true"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[9] || (_cache[9] = _createElementVNode("h2", null, "Customize Consent Preferences", -1)),
              _createElementVNode("button", {
                type: "button",
                class: "close",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClose()))
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("span", { "aria-hidden": "true" }, "×", -1)
              ]))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_6, [
                      _createTextVNode(" We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below. The cookies that are categorized as \"Necessary\" are stored on your browser as they are essential for enabling the basic functionalities of the site" + _toDisplayString(_ctx.isShowMoreDescription ? "." : "...") + " ", 1),
                      (!_ctx.isShowMoreDescription)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "show_more",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMoreDescription()))
                          }, " Show More "))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.isShowMoreDescription)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_7, " We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent. You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience. "))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _cache[19] || (_cache[19] = _createElementVNode("div", { class: "cookies_text border-bottom pb-3 pt-3" }, [
                  _createElementVNode("div", { class: "text_header mb-2" }, [
                    _createElementVNode("h6", null, "Necessary"),
                    _createElementVNode("p", { class: "active_text" }, "Always Active")
                  ]),
                  _createElementVNode("p", { class: "mb-2" }, " Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data. ")
                ], -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[11] || (_cache[11] = _createElementVNode("h6", null, "Functional", -1)),
                    _createElementVNode("label", _hoisted_10, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.functionalityStorage) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.functionalityStorage]
                      ]),
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "sliders round" }, null, -1))
                    ])
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "mb-2" }, " Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features. ", -1))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[14] || (_cache[14] = _createElementVNode("h6", null, "Analytics", -1)),
                    _createElementVNode("label", _hoisted_13, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.analyticsStorage) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.analyticsStorage]
                      ]),
                      _cache[13] || (_cache[13] = _createElementVNode("span", { class: "sliders round" }, null, -1))
                    ])
                  ]),
                  _cache[15] || (_cache[15] = _createElementVNode("p", { class: "mb-2" }, " Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc. ", -1))
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[17] || (_cache[17] = _createElementVNode("h6", null, "Advertisement", -1)),
                    _createElementVNode("label", _hoisted_16, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.adStorage) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.adStorage]
                      ]),
                      _cache[16] || (_cache[16] = _createElementVNode("span", { class: "sliders round" }, null, -1))
                    ])
                  ]),
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "mb-2" }, " Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns. ", -1))
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("button", {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onRejectAll()))
                  }, "Reject all"),
                  _createElementVNode("button", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSavePreference()))
                  }, "Save My Prefrences"),
                  _createElementVNode("button", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onAcceptAll())),
                    class: "active"
                  }, "Accept All")
                ])
              ])
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}