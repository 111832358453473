
 <template>
  <section class="space philosphy_outer">
    <div class="container">
      <h2 class="tittle_cm">The 3 key elements of our TOPics</h2>
      <p class="sub_text">
        A lot of thought goes into creating a topic. There are three vital
        <br />
        characteristics our team always does its best to delicately integrate
        <br />
        into each question. These three characteristics are the hallmarks of
        TopPick.
      </p>
      <div class="row">
        <div class="col-lg-4 col-md-6 mb-4">
          <div class="phil_card">
            <figure>
              <img src="@/assets/images/touching.jpg" alt="" />
            </figure>
            <div class="animate_text">
              <img src="@/assets/images/philospy-icon.png" alt="" />
              <p>
                We're fond of questions that trigger positive emotions and
                encourage people to talk about their personal experiences.
              </p>
            </div>
            <figcaption>Touching</figcaption>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-4">
          <div class="phil_card">
            <figure>
              <img src="@/assets/images/original.jpeg" alt="" />
            </figure>
            <div class="animate_text">
              <img src="@/assets/images/philospy-icon.png" alt="" />
              <p>
                We're constantly trying to come up with questions that are
                unique, nonbanal, and imaginative. This ensures the conversation
                will be a memorable one.
              </p>
            </div>
            <figcaption>Original</figcaption>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-4">
          <div class="phil_card">
            <figure>
              <img src="@/assets/images/home-pertinence.png" alt="" />
            </figure>
            <div class="animate_text">
              <img src="@/assets/images/philospy-icon.png" alt="" />
              <p>
                TopPick encompasses a ton of relevant questions. This guarantees
                you'll leave no stone unturned which can give you an extra dose
                of confidence.
              </p>
            </div>
            <figcaption>Pertinent</figcaption>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
export default {};
</script>
