import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hero_outer space" }
const _hoisted_2 = { class: "hero_content" }
const _hoisted_3 = { class: "search_tags" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, [
        _createElementVNode("span", null, "TopPick:"),
        _createTextVNode(" Find Interesting "),
        _createElementVNode("br"),
        _createTextVNode(" Topics and Make Conversation ")
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, " TopPick is a next-generation platform for finding engaging topics for conversations, language learning, writing, and much more... ", -1)),
      _createVNode(_component_search_bar, {
        searchResults: _ctx.searchResults,
        onOnSearch: _ctx.onSearch
      }, null, 8, ["searchResults", "onOnSearch"]),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("h5", { class: "montR text-white mt-4 my-3 pt-2" }, [
          _createTextVNode(" Today's "),
          _createElementVNode("span", { class: "cm-color" }, "Topics")
        ], -1)),
        _createElementVNode("div", null, [
          (_ctx.featuredTopics)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featuredTopics.slice(0, 6), (topic) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: topic.id
                  }, [
                    _createVNode(_component_router_link, {
                      to: {
                  name: 'Topic',
                  params: { slug: topic.slug, tab: _ctx.TopicPageTab.QUESTION },
                },
                      class: "cm_bg"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(topic.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featuredTopics.slice(6, 11), (topic) => {
            return (_openBlock(), _createElementBlock("li", {
              key: topic.id
            }, [
              _createVNode(_component_router_link, {
                class: "cm_bg",
                to: {
                name: 'Topic',
                params: { slug: topic.slug, tab: _ctx.TopicPageTab.QUESTION },
              }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(topic.title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ]),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featuredTopics.slice(11, 15), (topic) => {
            return (_openBlock(), _createElementBlock("li", {
              key: topic.id
            }, [
              _createVNode(_component_router_link, {
                class: "cm_bg",
                to: {
                name: 'Topic',
                params: { slug: topic.slug, tab: _ctx.TopicPageTab.QUESTION },
              }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(topic.title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}