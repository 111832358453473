import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/tp_logo.png'


const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "avtar_name"
}
const _hoisted_3 = {
  key: 2,
  class: "view_avtar_profile"
}
const _hoisted_4 = { class: "view_avtar_profile_wrap" }
const _hoisted_5 = { class: "view_avtar_profile_info" }
const _hoisted_6 = {
  key: 0,
  class: "avtar_image"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "avtar_name"
}
const _hoisted_9 = {
  key: 1,
  class: "avtar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "avtar",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickAvatar && _ctx.onClickAvatar(...args)))
  }, [
    (!_ctx.showDefaultImage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.user.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.user.image,
                alt: "",
                style: {"width":"35px","height":"35px"}
              }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_ctx.initials), 1)
              ])),
          (_ctx.showPreview)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.user.image)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("img", {
                            src: _ctx.user.image,
                            alt: "avtar"
                          }, null, 8, _hoisted_7)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.initials), 1)
                        ])),
                    _createElementVNode("div", null, [
                      _createElementVNode("h6", null, _toDisplayString(_ctx.user.username), 1),
                      _createVNode(_component_router_link, {
                        to: {
                  name: 'ViewProfile',
                  params: { username: _ctx.user.username },
                }
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("View profile")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "avtar",
            class: "img-fluid"
          }, null, -1)
        ])))
  ]))
}